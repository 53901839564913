@use "./mixins" as mix;
@use "./var" as var;
$background-color: #efefef;
$footer-color: #efefef;
html, body {
  margin: 0;
  padding: 0;
  overscroll-behavior-y: none;
  overscroll-behavior-x: none
}
* {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
}
.modal_container {
  @include mix.Test(var.$test-on);
  width: 100%;
  //max-width: 500px;
  height: auto;
  padding: 20px;
  //background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .modal_inner_container {
    color: black;
    border: 1px solid black;
    border-radius: 10px;
    padding: 20px;
    width: 100%;
    box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.5);

    p {
      margin-bottom: 15px;
      font-size: 16px;
    }

    label {
      display: block;
      margin-bottom: 10px;
      font-size: 14px;
      font-weight: bold;
    }

    input {
      width: 90%;
      padding: 10px;
      margin-bottom: 15px;
      border: 1px solid black;
      border-radius: 5px;
      background-color: rgba(255, 255, 255, 0.1);
      color: black;
    }

    button {
      width: 100%;
      padding: 10px;
      border: 1px solid black;
      border-radius: 5px;
      background-color: black;
      color: white;
      cursor: pointer;
      transition: background-color 0.3s;

      &:hover {
        background-color: #000000;
      }
    }
  }
}

.top_border {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 3px;
  background: linear-gradient(90deg, #4e4eff, #5aed5a);
  z-index: 1000;
}
.container {
  @include mix.Test(var.$test-on);
  display: grid;
  width: 100%;
  height: 300vh;
  overflow-y: hidden;
  grid-template-columns: 1fr 3fr 1fr;
  grid-template-rows: 1fr 10fr 1fr;
  grid-template-areas: "header header header"
                        "main_left main_center main_right"
                          "footer footer footer";

  background-color: $background-color;


  .header {
    @include mix.Test(var.$test-on);
    grid-area: header;
    width: 100%;
  }

  //.header {
  //  @include mix.Test(var.$test-on);
  //  grid-area: header_center;
  //  display: flex;
  //  flex-direction: column;
  //  justify-content: space-between;
  //  align-items: center;
  //
  //  p {
  //    @include mix.Test(var.$test-on);
  //    font-size: 48px;
  //    color: #000000;
  //  }
  //
  //  .logo_container {
  //    @include mix.Test(var.$test-on);
  //    display: flex;
  //    justify-content: center;
  //    align-items: center;
  //    width: 80%;
  //    height: auto;
  //
  //    img {
  //      width: 80%;
  //    }
  //  }
  //
  //  .spacer {
  //    @include mix.Test(var.$test-on);
  //    width: 60%;
  //    border: 1px solid #3e3e3e;
  //  }
  //
  //}
  //
  //.header_right {
  //  @include mix.Test(var.$test-on);
  //  grid-area: header_right;
  //  display: flex;
  //  align-items: center;
  //  justify-content: space-between;
  //  flex-direction: column;
  //
  //  .virtualContainer {
  //    @include mix.Test(var.$test-on);
  //  }
  //
  //  .btnContainer {
  //    @include mix.Test(var.$test-on);
  //    display: flex;
  //    align-items: center;
  //    justify-content: space-evenly;
  //    flex-direction: row;
  //
  //    .signInBtn, .signUpBtn {
  //      padding: 15px 20px;
  //      text-transform: uppercase;
  //      font-weight: bold;
  //      font-size: 16px;
  //      border-radius: 10px;
  //      border: none;
  //      cursor: pointer;
  //      transition: background-color 0.3s, color 0.3s;
  //    }
  //
  //    .signInBtn {
  //      background-color: $background-color;
  //      color: #191919;
  //
  //      &:hover {
  //        background-color: rgba(90, 90, 90, 0.5);
  //      }
  //    }
  //
  //    .signUpBtn {
  //      background-color: $background-color;
  //      color: #000000;
  //
  //      &:hover {
  //        background-color: rgba(255, 255, 255, 0.5);
  //        color: #000000;
  //      }
  //    }
  //  }
  //
  //  .spacer {
  //    @include mix.Test(var.$test-on);
  //    width: 60%;
  //    border: 1px solid #5e5a5a;
  //  }
  //}


  .main_left {
    @include mix.Test(var.$test-on);
    grid-area: main_left;
  }

  //.navigation_window {
  //  @include mix.Test(var.$test-on);
  //  grid-area: header_left / header_left / footer_left / header_left;
  //  position: absolute;
  //  top: 0;
  //  left: 0;
  //  height: 275vh;
  //  background-color: rgb(255, 255, 255);
  //  z-index: 2000;
  //  display: flex;
  //  flex-direction: column;
  //  overflow: hidden;
  //  justify-content: start;
  //  align-items: center;
  //
  //  &:before {
  //    content: "";
  //    position: absolute;
  //    top: 0;
  //    left: 0;
  //    width: 100%;
  //    height: 3px;
  //    background: linear-gradient(90deg, #5aed5a, #4e4eff);
  //    z-index: 1;
  //  }
  //
  //  .navigation_window_container {
  //    display: flex;
  //    flex-direction: column;
  //    overflow: hidden;
  //    justify-content: start;
  //    align-items: center;
  //
  //    .logo_container {
  //      @include mix.Test(var.$test-on);
  //      display: flex;
  //      justify-content: center;
  //      align-items: center;
  //      width: 80%;
  //      height: auto;
  //      margin-top: 30px;
  //      margin-bottom: 15px;
  //
  //      img {
  //        width: 100%;
  //      }
  //    }
  //
  //    .spacer {
  //      @include mix.Test(var.$test-on);
  //      border-bottom: 1px solid #000000;
  //      width: 40%;
  //      height: 3%;
  //    }
  //
  //    .navigation_bar_container {
  //      @include mix.Test(var.$test-on);
  //      width: 50%;
  //      height: auto;
  //      display: flex;
  //      justify-content: center;
  //      align-items: center;
  //      margin-top: 30px;
  //
  //      a {
  //        color: #000000;
  //        text-decoration: none;
  //        transition: opacity 0.3s ease;
  //
  //        &:hover {
  //          opacity: 0.7;
  //        }
  //      }
  //
  //      img {
  //
  //      }
  //    }
  //  }
  //}

  .main_center {
    @include mix.Test(var.$test-on);
    //border: #ffff46 solid 1px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    grid-area: main_center;

    .outlet_container {
      flex-grow: 1;
      width: 100%;
      height: 100%;
    }

    .response_container {
      //border: aqua 1px solid;
      @include mix.Test(var.$test-on);
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin-top: 50px;

      .response_internal_container {

        @include mix.Test(var.$test-on);
        display: flex;
        width: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .response {
          @include mix.Test(var.$test-on);
          width: 100%;
          display: inline-flex;
          justify-content: start;
          align-items: center;
          border: #000000 solid 1px;
          border-radius: 10px;

          .summary_label {
            color: #000000;
          }

          p {
            margin-left: 5px;
            @include mix.Test(var.$test-on);
            color: #0b0b0b;
          }
        }

        .button_container {
          @include mix.Test(var.$test-on);
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex-direction: row;

          .sub_button_container {
            display: flex;
            align-items: start;
            justify-content: start;
            flex-direction: row;
          }

          button {

            background-color: $background-color;
            color: #000000;
            border-radius: 5px;
            cursor: pointer;

          }

          button {
            margin-right: 30px;
            margin-top: 20px;
            padding: 10px 20px;
            background-color: $background-color;
            color: #000000;
            border-radius: 5px;
            cursor: pointer;

            &:hover {
              background-color: rgba(0, 0, 0, 0.5);
              color: #000000;
            }
          }
        }
      }

      .loadingUI {
        @include mix.Test(var.$test-on);
        display: flex;
        align-items: center;
        justify-content: center;
        width: 60px;
        height: 60px;
        border: 4px solid rgba(0, 0, 0, 0.1);
        border-top-color: #aec2cf;
        border-radius: 50%;
      }


    }

    .question {
      @include mix.Test(var.$test-on);
      width: 100%;
      height: auto;
      color: #000000;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      //border: 1px solid rgba(255, 255, 255, 0.5);
      border-radius: 10px;
      margin-top: 30px;

      .element_container {
        //border: red 1px solid;
        border: 1px solid rgba(0, 0, 0, 0.5);
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        @include mix.Test(var.$test-on);
        width: 100%;
        height: 70%;


        p {
          margin-bottom: 30px;
        }

        label {
          font-size: 12px;
          font-style: italic;
          color: #000000;
        }


        .small_input {
          width: 80%;
          height: auto;
          padding: 8px 10px;
          margin-top: 3px;
          border: none;
          outline: none;
          background-color: #000000;
          color: #ffffff;
          border-radius: 5px;
          position: relative;
          transition: box-shadow 0.3s ease;

          &:focus {
            box-shadow: 0 0 0 2px #4e4eff, 0 0 0 2.1px #5aed5a;
            background-clip: padding-box;
          }
        }

        .large_input {
          width: 80%;
          height: 100%;
          margin-top: 3px;
          border: none;
          outline: none;
          background-color: #000000;
          color: #ffffff;
          border-radius: 5px;
          position: relative;
          transition: box-shadow 0.3s ease;
          resize: none;

          text-align: left;
          overflow-wrap: break-word;
          word-wrap: break-word;
          white-space: pre-wrap;

          &:focus {
            box-shadow: 0 0 0 2px #f12d2d, 0 0 0 2.1px #ffff46;
            background-clip: padding-box;
          }

          &::placeholder {
            text-align: left;
            vertical-align: top;
            line-height: normal;
          }
        }

        button {
          margin-top: 20px;
          width: 100%;
          max-width: 300px;
          height: auto;
          padding: 10px;
          background-color: $background-color;
          color: #000000;
          border-radius: 5px;
          cursor: pointer;
          font-size: 16px;

          &:hover {
            background-color: rgba(0, 0, 0, 0.5);
            color: #000000;
          }

          @media (max-width: 768px) {
            font-size: 14px;
            padding: 8px;
          }

          @media (max-width: 480px) {
            font-size: 12px;
            padding: 6px;
          }
        }
      }
    }
  }

  .main_right {
    @include mix.Test(var.$test-on);
    grid-area: main_right;
    display: flex;
    justify-content: center;
    align-items: start;

    .checklist_container {
      @include mix.Test(var.$test-on);
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin-top: 20px;

      .checklist_item {
        display: flex;
        justify-content: flex-start;
        align-items: start;
        flex-direction: column;

        p {
          color: #000000;
          margin-bottom: 2px;
        }

        .spacer {
          @include mix.Test(var.$test-on);
          border: 0.3px solid #000000;
          width: 100%;
        }
      }
    }
  }

  .footer {
  @include mix.Test(var.$test-on);
  grid-area: footer;
  background-color: $footer-color;
  }


  .star-rating {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    flex-direction: column;
  }

  .star {
    transition: color 0.2s;
  }

  .star.full {
    color: #ffc107;
  }

  .star:not(.full) {
    color: #e4e5e9;
  }

  .half-star-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
    background-color: #ffc107;
    z-index: 1;
    pointer-events: none;
  }

  .star_inner_container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
  }

}